@import './../../global.scss';

.scenario-chart {
	height: 100%;
	display: flex;
	flex-direction: column;
	&__message {
		color: #383838;
	}

	&__select {
		margin-left: 35px;
		padding-right: 20px;

		.MuiSelect-select {
			&:focus {
				background-color: transparent;
			}
		}
	}
	&__saving-status {
		margin-right: 24px;
		color: rgba(0, 0, 0, 0.6);
		font-size: 10px;
		text-align: left;
	}
}

.scenario-chart__header,
.scenario-chart__body {
	font-size: 12px;
	font-family: $body-font;
	button:not(.defaultActionBtn)[class*='scenario-chart'] {
		background-color: #f7f7f7;
		border: none;
		border-radius: 4px;
		padding: 0 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 32px;
		cursor: pointer;
	}
	button[class*='scenario-chart'] img {
		margin-right: 10px;
	}
}

.scenario-chart__header {
	min-height: 58px;
	border-bottom: 1px solid #e8e8e8;
	display: flex;
	align-items: center;
	padding: 0px 24px;
	&-slot {
		flex: 1;
		&--left {
			text-align: left;
		}
		&--right {
			text-align: right;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		&--center {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		button {
			min-height: 34px !important;
			min-width: 60px !important;
		}
	}
}

.scenario-chart__sideBar-container {
	background: white;
	height: 100%;
	width: 100%;
	padding: 24px;
	text-align: center;
	hr {
		color: #e8e8e8;
		margin-top: 24px;
	}
}

.scenario-chart__body {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	height: calc(100% - 58px);

	.react-flow {
		background-color: #fafcff;
		border: 1px solid #fafcff;
	}
}

.scenario-chart__label {
	font-size: 12px;
	padding: 0;
	display: flex;
	&--bold {
		font-weight: 600;
		margin-bottom: 10px;
	}
	> img {
		margin-right: 8px;
	}
}
.scenario-chart__input {
	margin-left: 50px;
	height: 32px;
	max-width: 170px;
}
.scenario-chart__input {
	font-size: 12px;
	padding: 5px 10px;
	border-radius: 4px;
	flex: 1;

	&:focus,
	&:hover {
		border-radius: 4px;
	}
}

.scenario-chart__control {
	margin: 0 !important;
	padding: 10px 0;
	display: flex !important;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		padding-bottom: 0;
	}
	&:first-child {
		padding-top: 0;
	}
	> button {
		width: 100%;
		margin: auto;
		img {
			margin-right: 10px;
		}
	}
	&-checkbox {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		input {
			width: 36px;
			height: 36px;
		}
	}
}

.MuiInputBase-root,
.MuiFormControl-root,
.MuiSlider-root,
.scene-widgets__sort-input-control {
	&:not(.scene-widgets__transform-input):not(.scene-widgets__sort-input):not(.MuiTablePagination-input) {
		flex-basis: 60% !important;
	}
}

.MuiFormControl-root.scene-widgets__transform-input
	> .MuiInputBase-root.MuiOutlinedInput-root:not(.scene-widgets__sort-input):not(.MuiTablePagination-input) {
	flex-basis: auto !important;
}
