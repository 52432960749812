@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url('./assets/fonts/poppins/Poppins-Regular.ttf') format('TrueType');
}

@font-face {
	font-family: 'Lora';
	src: local('Lora'), url('./assets/fonts/lora/Lora-Bold.ttf') format('TrueType');
}

@font-face {
	font-family: 'Open Sans';
	src: local('Open Sans'), url('./assets/fonts/openSans/OpenSans-Regular.ttf') format('TrueType');
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto'), url('./assets/fonts/roboto/Roboto-Regular.ttf') format('TrueType');
}

$header-bg-color: #ffffff;
$header-login-link-color: #b7b7b7;
$body-bg-color: #f3f3f3;
$gray-links-color: #bfbfbf;
$customer-primary: #126cfc;
$customer-primary-old: #2ebedc;
$light-gray: #313131;
$primary: #99ece1;
$primary-other: #70c1c2;
$input-placeholder-text: #b7b7b7;
$btn-primary-bg-color: #125faa;
$btn-primary-bg-color-hover: #00468b;
$link-color: $customer-primary;
$top-header-links-color: #878cac;
$navigation-panel-bg-color: #1b1d2a;
$body-bg-color-after-login: #f1f1f1;
$thin-left-panel-bg-color: #f4f4f4;
$default-label-color: #000;
$body-table-font-color: #3b3b3b;
$gray-color-button-color: #a8a8a8;
$text-inputs-border-color: #d6d6d6;
$table-borders-color: #e8e8e8;
$default-label-text-color: #818181;
$default-alert-btn-bg-color: #fd5e6b;
$default-alert-btn-bg-hover-color: #f44352;
$menuItems-bg-color-hover-old: rgba(0, 0, 0, 0.08);
$menuItems-bg-color-hover: #e5eaed;
$button-svg-bg-color: #878cac;
$media-item-header-bg-color: #6f7775;
$checkboxes-highlighted-color: #126cfc;
$checkboxes-border-color: #bfbfbf;
$media-content-border-color: #e5e9ec;
$content-border-color: #e5e9ec;
$disabled-btn-color: #cccccc;
$tooltip-color: rgba(0, 0, 0, 0.7);
$tooltip-text-color: #fff;
$primary-icon-color: #1d1d1d;
$primary-filtering-icons-color: #6c7473;
$faded-breadcrumb-link-color: #126cfc;
$faded-breadcrumb-link-color-hover: #0053d8;
$advanced-search-background-color: #fdfdfd;
$svg-icons-extra-color: #707070;
$svg-alternative-color: #6b748f;
$dropdowns-background-color: #f5f5f5;
$textarea-font-color: #a2a2a2;
$dashboard-background-color-bottom: #eff0f5;
$dashboard-banner-bg-color: #00b1d6;
$thin-faded-border-color: #ddd;
$media-thumbnail-bg-color: #e5e9ec;
$go-btn-bg-color-hover: #0053d8;
$cancel-button: #4e4c4c;
$cancel-button-hover: #1c1c1c;
$btn-default-color-active: #126cfc;
$btn-default-color-hover: #0053d8;
$live-interact-header: #2a2e38;
$live-interact-element-list: #373c4a;
$live-interact-element-hover: #444b60;
$movable-grid-color: #323641;
$dark-mode-popup-border-color: #126cfc;
$dark-mode-element-hover: #20242c;

$spacing-Z: 0;
$spacing-XS: 1rem;
$spacing-S: 2rem;
$spacing-M: 3rem;
$spacing-L: 4rem;
$spacing-XL: 5rem;
$spacing-XXL: 6rem;

$navigation-panel-closed-width: 0px;
$navigation-panel-closed-width-media: 73px;
$navigation-panel-open-width: 240px;
$navigation-items-width: 62px;
$navigation-items-height: 56px;
$navigation-header-height: 93px;

$body-font: 'Poppins';
$X-small-font-size: 10px;
$small-font-size: 11px;
$default-font-size: 12px;
$large-font-size: 13px;
$X-large-size: 14px;
$XX-large-size: 15px;
$title-font-size: 24px;
$dashboard-width: 1140px;

$font-bold: bold;

html {
	font-size: $default-font-size;
	position: relative;
}

html,
body {
	height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-size: 1.5rem !important;
	font-family: $body-font;
	margin: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color: $default-label-color;
	background-color: $header-bg-color !important;
}

// body > div:first-child {
//   display: flex;
//   width: 100%;
//   height: 100%;
//   max-height: 100%;
// }

input,
button {
	padding: 0;
	font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	color: $default-label-color;
	font-family: inherit;
}

a {
	margin-right: $spacing-S;
	color: $link-color;
	font-size: $default-font-size;
	text-decoration: none;
	cursor: pointer;
	font-family: inherit;
	&.btn--disabled {
		opacity: 0.6;
	}
}

div[class*='makeStyles-root'] {
	top: 65px !important;
	z-index: 90000;
}

.boldedText {
	font-weight: $font-bold !important;
	font-size: $large-font-size !important;
}

label {
	font-size: $default-font-size;
	font-family: inherit;
}

select {
	font-size: 12px;
}
.MuiDialog-container {
	background-color: rgba(0, 0, 0, 0);
	width: 100vw;
	outline: 0;
	left: 0px !important;
	position: absolute;
}

input,
textarea {
	font-family: $body-font;
}

@mixin textEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin alignTextImageCenter {
	display: flex;
	align-items: center;
}

@mixin addGreyScrollBar {
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #eaeaf2;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: #eaeaf2;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: $primary-filtering-icons-color;
		cursor: grab;
	}
}

@mixin addWhiteScrollBar {
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 8px darkgray;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: #ffffff;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #ffffff;
		cursor: grab;
	}
}

.fullWidth {
	width: 100% !important;
}
.emptyMarginTop {
	margin-top: 0;
}

/*input[type='text'], textarea {
  &:focus { outline: none }
}*/
header {
	display: flex;
}

select::-ms-expand {
	display: none !important;
}
select {
	-webkit-appearance: none;
	appearance: none;
}
.flexWrapper {
	display: flex;
}
div[role='dialog'],
.dialogContentWrapper,
.MuiTypography-root {
	font-family: $body-font !important;
}

.MuiTooltip-popper {
	z-index: 9999999 !important;
}
