@import "../../../global";


.LiveManagerScreenWrapper {
  width: 100%; //calc(100% - 5px);
  display: block;
  background-color: $movable-grid-color;
  height: calc(100% - 50px);
  letter-spacing: 0.5px;

  &.moderatorMode {
    height: 100%;
  }
  .LivePlayerWrapper {
    width: 100%;
    height: 100%;
  }
  #settingsPanel {
    > div:nth-child(2) {
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100% - 25px) !important;
      overflow-y: auto;
    }
  }
  .liveManagerSemiboldHeaders {
    font-weight: 600;
  }
  .LiveManagerBottomPanelContainer {
    display: flex;
    text-align: center;
    .zoomOptions {
      float: right;
      @include alignTextImageCenter;
      .zoom {
        padding: 2px;
        background-color: $live-interact-element-list !important;
        &:hover {
            background-color: $live-interact-element-hover !important;
        }
        width: 50px;
        border: 0px;
        cursor: pointer;
        margin-right: 5px;
      }
      img {
        cursor: pointer;
        width: 15px;
        height: 15px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .timelineActionButtons {
      margin: 0 auto;
      display: flex;
      align-content: center;
    }
    button {
      letter-spacing: 0.5px;
      cursor: pointer !important;
      color: #ffffff;
      text-transform: none !important;
      background-color: $live-interact-element-list;
      height: 24px !important;
      margin-right: 5px;
      img {
        margin-right: 5px;
      }
      &:disabled {
        opacity: 0.4;
      }
      &:hover {
        background-color: $live-interact-element-hover;
      }
    }
    button.live-time {
        margin-left: 5%;
        min-width: 120px !important;
        font-size: inherit;
    }
  }
  .LiveManagerBottomPanelContainer {
    .snapshotText {
      margin-right: 10px;
    }
    label {
      display: flex;
      align-items: center;
    }
  }
}
