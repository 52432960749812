.scenario-editor {
	&__main {
		background-color: #f7f8fa;
		flex: 1;
		padding-right: 5px;
		display: flex;
		flex-direction: column;
		&--mobile {
			.scenario-editor__layout {
				padding-bottom: 177.78% !important;
				height: 100%;
			}
			.scenario-editor__player-container > div {
				height: auto;
				width: 30%;

				@media only screen and (max-width: 2100px) {
					& {
						width: 30%;
					}
				}
				@media only screen and (max-width: 1920px) {
					& {
						width: 25%;
					}
				}
				@media only screen and (max-width: 1440px) {
					& {
						width: 20%;
					}
				}
			}
		}
	}
	&__player-container {
		border-bottom: 1px dashed #ced4d8;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		// padding: 24px;
		.scenario-editor__player-editor {
			width: 100%;
			position: relative;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.scenario-editor__layout {
			padding-bottom: 56.25%;
			width: 100%;
			text-align: center;
			cursor: default;
			&:hover {
				border: 1px dashed #ced4d8;
			}
		}
	}
	&__timeline-container {
		display: flex;
		flex-direction: column;
		position: relative;
		height: auto;
		max-height: 40%;
		min-height: 250px;
	}
	&__tab-content {
		padding-bottom: 24px;
	}
	&__tab-layout {
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 150px;
		background-color: #fff;
		flex: 1;
		padding: 24px;
		&--mobile {
			grid-template-columns: repeat(4, 1fr);
			.scenario-editor__layout {
				padding-right: 56.25% !important;
				max-width: 100px;
				height: 180px;
			}
		}
		&:not(.scenario-editor__tab-layout--mobile) .scenario-editor__layout {
			padding-bottom: 56.25% !important;
		}
	}
	&__layout {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px dashed #ced4d8;
		background-color: #f7f8fa;
		position: relative;
		&:not(.scenario-editor__layout--disabled) {
			cursor: pointer;
			&:hover {
				border: 1px dashed #126cfc;
			}
		}

		&--selected {
			border: 1px solid #126cfc;
		}
		&--item {
			border: 1px dashed #ced4d8;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #cdd4d9;
		}
	}

	&__widgets-overlay {
		position: absolute;
		text-align: center;
		cursor: default;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&__tab-widgets {
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 100px;
		padding: 12px;
		background-color: #fff;
		flex: 1;
		padding: 24px;
		&--message {
			color: #000;
			padding-bottom: 24px;
			border-bottom: 1px solid #ebebeb;
		}
		&-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			font-size: 12px;
		}
	}
	&__widget {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background-color: #f7f8fa;
		color: #cdd4d9;
		transition: all 0.2s;

		&:not(.scenario-editor__widget--disabled) {
			cursor: pointer;
			&:hover {
				color: #126cfc;

				& .widget-name {
					color: #126cfc;
				}
			}
		}
		svg {
			width: 2em;
			height: 1.5em;
			margin-bottom: 8px;
		}
		&--selected:not(.scenario-editor__widget--disabled) {
			color: #126cfc;

			& .widget-name {
				color: #126cfc;
			}
		}
		&--disabled {
			opacity: 0.4;
		}

		& .widget-name {
			color: #000;
		}
	}
	&__tab-widgets--message {
		padding: 24px;
		color: #00000099;
		text-align: center;
	}
	&__tab-edit--breadcrumb {
		padding: 24px;
		display: inline-block;
		.tab-edit__breadcrum-item {
			color: #00000099;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
		}
		a.tab-edit__breadcrum-item {
			padding: 0 !important;
			margin: 0 !important;
			max-width: 100px;
			&:not(.tab-edit__breadcrum-item--current) {
				text-decoration: underline !important;
			}
			&.tab-edit__breadcrum-item--current {
				cursor: default;
			}
		}
		span.tab-edit__breadcrum-item {
			padding: 0 5px;
			font-size: 9px;
		}
	}
	&-box {
		&__content {
			display: flex;
			align-items: center;
			min-height: 58px;
		}

		&__img {
			margin-right: 8px;
		}

		&__actions {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			&--item {
				height: 100%;
				border-left: 1px solid #ebebeb;
				min-height: 58px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: 0 8px;
			}
		}

		&__dragHandler {
			margin-right: 8px;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.MuiFormControlLabel-root {
		margin-left: 0 !important;
	}

	.scene-widget__control {
		width: 100%;
		&:not(:last-child) {
			margin-bottom: 12px;
		}
		&:not(.scene-widget__control-column) {
			display: flex !important;
			align-items: center;
			justify-content: space-between;
		}
		&-column {
			.scene-widget__control-label {
				margin-top: 20px;
				margin-bottom: 5px;
			}
			&:not(:last-child) {
				margin-bottom: 12px;
			}
			&--item {
				display: flex;
				align-items: flex-start;
				justify-self: flex-start;
			}
			.scene-widget__lock-btn {
				border: 1px solid #ced4d8 !important;
				border-radius: 4px;
				width: 20px !important;
				height: 20px !important;
				padding: 0;
				margin-left: 5px;
				margin-top: 3px;
				&--active {
					border: 1px solid #326af3 !important;
				}
				img {
					width: 8px;
					height: 12px;
				}
			}
		}
		&:not(.scene-widget__control--disabled) {
			.scene-widget__fileUpload--placeholder:hover,
			.scene-widget__iconSelector--placeholder:hover {
				border: 1px solid #8e8e8e;
				cursor: pointer;
			}
		}
	}
	.scene-widget__buttonGroup {
		width: 100%;
		button {
			flex-basis: 50% !important;
		}
	}
	.scene-widget__fileUpload--placeholder {
		height: 85px;
		width: 150px;
		border: 1px solid #ebebeb;
		border-radius: 4px;
		margin-right: 10px;
		svg {
			fill: #ebebeb;
			height: 100%;
			width: 100%;
			cursor: inherit;
		}
	}
	.scene-widget__iconSelector--placeholder {
		height: 85px;
		width: 150px;
		border: 1px solid #ebebeb;
		border-radius: 4px;
		margin-right: 10px;
		svg {
			height: 100%;
			width: 100%;
			cursor: inherit;
		}
	}
	.scene-widgets__sort-input-control {
		display: flex;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		> *:not(:last-child):not(.dragging-value-button) {
			margin-right: 10px;
		}
		> .MuiInputBase-root {
			flex: 1;
		}
	}
	.scene-widget__control-container {
		display: grid;
		gap: 5px;
		grid-template-columns: repeat(4, 1fr);
	}
	.scene-widgets__transform-input {
		input {
			border-radius: 4px;
			font-size: 12px;
			text-align: center;
			padding: 6px 0px 6px 6px;
		}
		.MuiFormHelperText-root {
			text-align: center !important;
		}
	}
	.colorPickerSelection {
		width: 40px;
		height: 22px;
		border: 2px solid white;
		border-radius: 0;
		margin-right: 15px !important;
		display: block;
		position: relative !important;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
	.TimeInput {
		border: none;
		border-bottom: 2px solid transparent;
		background-color: #ffffff;
		padding-right: 0;
		padding-left: 0;
		width: 100%;
		position: relative;
		&::before {
			left: 0;
			right: 0;
			bottom: 0;
			content: '\00a0';
			position: absolute;
			transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			border-bottom: 1px solid rgba(0, 0, 0, 0.42);
			pointer-events: none;
		}
		&.TimeInput--disabled::before {
			border-bottom-style: dotted;
		}
		&:not(.TimeInput--disabled):not(.TimeInput--readonly):hover {
			background-color: #ffffff;
			border: none;
			border-bottom: 2px solid rgba(0, 0, 0, 0.87);
			&::before {
				border-bottom-color: transparent;
			}
		}
		&:focus {
			background-color: #ffffff;
			border: none;
			border-bottom: 2px solid #126cfc;
		}
	}

	.scene-widget__general-settings.scene-widget__control-column .scene-widget__control-label {
		margin-top: 10px;
	}
}
