.playerConfigurations {
	display: flex;
	width: 100%;
	&__no-data {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		label {
			margin-top: 24px;
		}
	}
}

.playerConfigurations-main {
	background-color: #f2f5f7;
	flex: 1;
	overflow: auto;
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 600;
		padding: 12px 24px;
		height: 46px;
		background-color: white;
	}
}

.playerConfigurations-preview {
	width: 40%;
	background: white;
	.LivePlayerWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.playerConfigurations-subheader {
	font-size: 12px;
	padding: 12px 24px;
	height: 46px;
	margin-top: 2px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:not(.playerConfigurations-subheader--readonly) {
		background-color: white;
		cursor: pointer;
		&:hover {
			background-color: #e5eaed;
		}
	}
	&--readonly {
		font-weight: 600;
	}
}

.playerConfigurations_tooltip-container {
	position: relative;
	height: 25px;
	&:hover .tooltiptext {
		display: block;
	}
	.tooltiptext {
		top: -26px;
		left: -80px;
		max-width: none;
		opacity: 1;
		border-radius: 4px;
	}
}

.playerConfigurations-preset-name {
	padding: 12px 24px;
	font-size: 12px;
	input {
		border: 1px solid #dfe7eb;
		border-radius: 5px;
		padding: 8px 14px;
		min-height: 30px;
		margin-right: 10px;
		min-width: 350px;
		font-size: 12px;
		background: white !important;
	}
	&__input {
		margin-top: 12px;
		display: flex;
		align-items: center;
	}
}

.playerConfigurations-arrow:not(.playerConfigurations-arrow--expanded) {
	width: 5px;
}

.middleZone__title {
	font-size: 14px;
	font-weight: 600;
	padding: 12px;
	height: 46px;
	background-color: white;
}

.saved-presets,
.playerConfigurations-presets {
	padding: 24px;
	font-size: 12px;
	background: #f2f5f7 0% 0% no-repeat padding-box;
}

.playerConfigurations-presets {
	border-top: 1px solid #dfe7eb;

	.playerConfigurations-presets__container {
		margin-top: 10px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 120px;
		grid-gap: 0 15px;
	}

	.playerConfigurations-presets__item {
		background-size: cover !important;
		background-position: center center;
		color: white;
		padding: 5px;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		border: 3px solid transparent;
		transition: margin 0.05s ease-in-out;
		cursor: pointer;
		&--selected {
			border-color: #126cfc;
		}
		&--enterprise {
			background-image: url('../../../../../assets/images/computer.png');
		}
		&--interactive {
			background-image: url('../../../../../assets/images/laptop.png');
		}
		&--shopping {
			background-image: url('../../../../../assets/images/phone.png');
		}
		&--recommended .playerConfigurations-presets__item__info span {
			display: inline-block;
		}
		&:hover {
			margin: 2px;
		}
	}

	.playerConfigurations-presets__item__info {
		display: flex;
		justify-content: flex-end;
		flex: 1;
		span {
			height: 22px;
			font-size: 10px;
			line-height: 22px;
			background: #126cfc;
			padding: 0 5px;
			border-radius: 2px;
			margin-left: 5px;
			display: none;
		}
		button {
			cursor: pointer;
			width: 24px;
			height: 24px;
			border: none;
			border-radius: 4px;
			border: 1px solid transparent;
			&:hover {
				border-color: #126cfc;
			}
		}
	}

	.playerConfigurations-presets__item__label {
		padding: 0 5px;
		cursor: inherit;
		&--title {
			font-weight: 600;
			margin-bottom: 5px;
		}
	}
}

.saved-presets {
	border-bottom: 1px solid #dfe7eb;
	padding-top: 0px;
}

.saved-preset-select__control,
.playerConfigurations-card__control {
	.MuiInputBase-root {
		background-color: white;
		border-radius: 4px !important;
		&.Mui-focused,
		&:not(.Mui-disabled):focus,
		&:not(.Mui-disabled):hover {
			border-color: #126cfc;
			background-color: white;
			border-radius: 4px !important;
		}
		.MuiSelect-select.MuiSelect-select {
			border-color: transparent;
		}
	}
	.MuiSelect-root {
		padding: 10px 12px;
	}
	.MuiSelect-icon {
		top: calc(50% - 10px);
	}
}

.saved-preset-select__control {
	.MuiInputBase-root {
		border: 1px solid #dfe7eb;
	}
	.saved-preset-option__action {
		display: none !important;
	}
}

.playerConfigurations-card__control {
	.MuiInputBase-root {
		border: 1px solid #f4f4f4;
		min-width: 100px;
	}
}

.saved-preset-option {
	.saved-preset-option__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&--edit,
	&--delete {
		display: none;
	}

	&:hover {
		.saved-preset-option {
			background-color: #f2f5f7;
			&__action button {
				display: block;
			}
		}
	}
}

.saved-preset-option__action {
	display: flex;
	align-items: center;
	button {
		cursor: pointer;
		width: 24px;
		height: 24px;
		border: none;
		border-radius: 4px;
		margin-right: 10px;
		border: 1px solid transparent;
		&:hover {
			border-color: #126cfc;
		}
	}
}

div[role='presentation'] li.saved-preset-option:not(.treeCatalogItem) {
	height: 32px;
	min-height: 0px;
	padding: 0 14px 0 24px;
}

.playerConfigurations {
	&-devices {
		padding: 12px 24px 0px 24px;
		border-bottom: 1px solid #dfe7eb;
		font-size: 12px;
		button {
			font-size: inherit;
			height: 40px;
			border: none;
			background-color: transparent;
			cursor: pointer;
			border-bottom: 4px solid transparent;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			&.playerConfigurations-devices--selected,
			&:hover {
				border-bottom: 4px solid #126cfc;
			}
			&.playerConfigurations-devices--selected {
				font-weight: 600;
			}
			&:not(:last-child) {
				margin-right: 24px;
			}
			img {
				height: 14px;
				margin-right: 8px;
			}
		}
	}
	&-notice {
		font-size: 12px;
		text-align: center;
		padding: 12px 0px;
	}
}

.playerConfigurations-card {
	background: white;
	border-radius: 2px;
	&:not(:first-child) {
		margin-top: 12px;
	}

	&__header {
		margin-bottom: 2px;
		padding: 12px 24px;
		border-bottom: 2px solid #f2f5f7;
		font-size: 14px;
		font-weight: 600;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		height: 46px;
		&:hover {
			background-color: #e5eaed;
		}
	}

	&__header-actions {
		display: inline-flex;
	}

	&__refresh-btn {
		color: #126cfc;
		background: transparent;
		border: none;
		font-size: 12px;
		margin-right: 24px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 12px 0;
		img {
			margin-right: 5px;
			margin-bottom: 2px;
		}
		&:not(&--disabled):hover {
			text-decoration: underline;
			cursor: pointer;
		}
		&--disabled {
			opacity: 0.5;
		}
	}

	&__content {
		padding: 12px 48px;
		.MuiFormControlLabel-root {
			margin-right: 0 !important;
			&:hover {
				background-color: #f2f5f7;
			}
		}
		.MuiIconButton-colorSecondary:hover {
			background-color: transparent !important;
		}
		.MuiFormHelperText-root {
			font-size: 0.85rem;
		}
	}

	&-list {
		padding: 0px 24px 100px;
	}

	&__control,
	&__color-picker {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__control {
		justify-content: space-between;
		font-size: 12px;
		min-height: 36px;
		padding: 0 12px;
		margin-left: -12px;
		&:hover {
			background-color: #f2f5f7;
			.MuiFormControlLabel-root {
				background-color: #f2f5f7;
			}
		}
		&-end {
			justify-content: flex-end;
		}
	}

	&__color-picker {
		color: #898989;
		position: relative;
		padding-right: 5px;

		> * {
			margin-left: 5px;
			&:first-child {
				margin-right: 10px;
			}
		}

		input[type='number'] {
			width: 52px;
			text-align: right;
			padding: 5px;
		}

		.colorPickerSelection {
			border-radius: 4px;
			display: flex;
		}
	}

	input {
		border: 1px solid #f4f4f4;
		border-radius: 4px;
		font-size: 12px;
	}
	.playerConfigurations-card__accountname-input {
		padding: 8px 14px;
		height: 32px;
		min-width: 170px;
		background-color: #fff;
	}

	.playerConfigurations-card__play-cover-input {
		height: 32px;
		min-width: 170px;
		background-color: #fff;
		border: 1px solid #f4f4f4;

		input {
			border: 0;
		}

		fieldset {
			border: 0;
		}
	}

	.playerConfigurations-card__input-error {
		border-color: #fd5e6b;
		&:focus,
		&:hover {
			border-color: #f44352;
		}
	}

	&__opacity {
		width: 36px;
		border: 1px solid #f4f4f4;
	}
}

.playerConfigurations-actions {
	background: white;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 0 0;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-top: 2px solid #f2f5f7;
	flex-wrap: wrap;

	button {
		font-weight: 600;
		height: 30px;
	}

	> *:not(:last-child) {
		margin-right: 12px !important;
	}

	.MuiButton-label {
		line-height: 1;
	}

	.playerConfigurations-actions__change-notice {
		width: 100%;
		padding: 0px 10px 10px 10px;
		text-align: center;
		display: flex;
		flex-direction: column;
	}
}

.dialogContent__item {
	font-size: 12px;
	label,
	input {
		display: block;
		margin-bottom: 10px;
	}
	input {
		border: 1px solid #bfbfbf;
		border-radius: 5px;
		padding: 5px 24px;
		min-height: 30px;
		width: 100%;
	}
	&.saved-preset-option__action {
		justify-content: flex-end;
		.saved-preset-option--star {
			margin-left: 10px;
			margin-right: 0 !important;
		}
	}
}

#playerConfigurations-livePlayer .LivePlayerWrapper {
	padding-bottom: 56.25%;
	position: relative;
	margin-right: 5px;
	#player-preview {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.screenParentContainer.mobileView .profileScreenWrapper {
	.playerConfigurations {
		display: flex;
		flex-direction: column-reverse;

		.playerConfigurations-preview {
			width: 100%;
			margin-bottom: 12px;
		}

		#playerConfigurations-livePlayer {
			padding: 0;
			background-color: #ffffff;
		}
	}

	.playerConfiguration-sub-menu {
		width: 100%;
	}
}
