.filter-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 24px 12px 12px;
}

.filter-options {
	width: 100%;
	font-size: 12px;
	letter-spacing: 0px;
	color: #00000099;
	display: flex;
	align-items: center;
	button,
	label,
	span {
		font-size: 12px !important;
		letter-spacing: 0px !important;
		color: #00000099 !important;
	}

	.restore-btn__label {
		color: #fff;
	}

	&_item {
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		margin-right: 8px;
		&:first-child {
			margin-left: 12px;
		}
		> * {
			height: 100%;
		}
	}
}

#searchInputContainer {
	display: flex;
	flex-direction: row;
	height: 37px;
	align-items: center;
}

.search-filter-btn {
	border: none;
	border-radius: 4px;
	display: flex;
	cursor: pointer;
	position: relative;
}

.searchInputWrapper {
	font-size: 11px !important;
	float: left;
	cursor: pointer;
	border-radius: 5px;
	margin-right: 5px;
	display: flex;
	flex-direction: row;
	height: 37px;
	align-items: center;

	input {
		outline: none;
	}
	#searchInputBox {
		box-sizing: content-box;
		font-family: inherit;
		font-size: 100%;
	}
	input::-webkit-search-decoration,
	input::-webkit-search-cancel-button {
		display: block;
		cursor: pointer;
	}

	#searchInputBox {
		height: 30px;
		padding: 0 10px 0 15px;
		border: none;
		background: url('../../../assets/images/search_new.svg') no-repeat 12px center;
		background-size: 15px 15px;

		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;

		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		transition: all 0.5s;

		&:hover {
			box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
			border-radius: 4px;
		}
	}
	#searchInputBox:focus {
		width: 150px;
		background-color: #fff;
		border: 1px solid #1d71f8;
	}

	input:-moz-placeholder {
		color: #999;
	}
	input::-webkit-input-placeholder {
		color: #999;
	}

	#searchInput {
		position: relative;
		&.expanded {
			background-color: #cfd5e5;
			border-radius: 4px;
		}
	}
	#searchInput #searchInputBox {
		width: 0px;
		padding-left: 15px;
		cursor: pointer;
		height: 100%;
	}
	#searchInput #searchInputBox:hover {
		background-color: #fff;
	}
	#searchInput #searchInputBox:focus {
		width: 150px;
		padding-left: 35px !important;
		color: #000;
		background-color: #fff;
		cursor: auto;
	}
	#searchInput input:-moz-placeholder {
		color: transparent;
	}
	#searchInput input::-webkit-input-placeholder {
		color: transparent;
	}
	#searchInput {
		height: 32px;
		img {
			position: absolute;
			right: 5px;
			padding: 5px 0px;
			display: none;
			top: 0;
		}
		&.hasvalue:focus-within,
		&.hasvalue.expanded {
			img {
				display: block;
			}
		}
	}
	.expandedSearchInput {
		font-size: 11px !important;
		width: 150px !important;
		background-color: #f4f5f7 !important;
		padding-left: 40px !important;
		&:hover {
			box-shadow: 2px 2px 5px 0px rgba(184, 172, 172, 0.98);
			border-radius: 4px;
		}
		&:focus {
			background-color: #fff !important;
			border: 1px solid #1d71f8 !important;
		}
	}
	.collapsedSearchInput {
		padding: 0 25px 0 15px !important;
	}
}
