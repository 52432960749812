.scenario-chart-side-bar {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 25%;
    border-right: 1px solid #E8E8E8;
    min-width: 350px;
    &__content {
        background: #F7F8FA;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}