.player-preview {
	&__embed-setting {
		background-color: #f2f5f7;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		padding: 12px;
		font-size: 14px;
		font-weight: 600;
	}

	.LivePlayerWrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__player-setting-section,
	&__embed-section,
	&__information-section {
		border-top: 1px solid #dfe7eb;
		padding: 24px;
	}
	&__player-setting-options {
		margin-top: 16px;
	}
	.saved-preset-select__control {
		.saved-preset-select__control-header {
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	&__size-options {
		margin-top: 12px;
	}
	&__link {
		font-weight: 600;
		display: inline-flex;
		margin-right: 0px;
		svg {
			margin-right: 5px;
			margin-left: -3px;
			width: 18px;
		}
		&:hover {
			font-weight: bolder;
			color: #0053d8;
		}
	}
	&__fixed-size {
		flex-direction: row !important;
		display: flex;
		align-items: center;
	}
	&__fixed-size-inputs {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		margin-left: 12px;
		input {
			border-radius: 4px;
			border: 1px solid #f4f4f4;
			background: #fff;
			width: 100px;
			height: 36px;
			padding: 5px;
			font-size: 12px;
		}
		span {
			margin: 0 10px;
		}
	}
	&__select {
		margin: 5px 0px 0px !important;
		.MuiTypography-root {
			width: 100% !important;
			margin-bottom: 5px;
		}
		.MuiSelect-root {
			padding: 12px !important;
		}
		.MuiInputBase-formControl {
			width: 100% !important;
		}
	}
	.MuiFormControlLabel-label {
		font-size: 12px !important;
	}
	&__embed-code-container {
		display: flex;
		align-items: flex-start;
		padding-bottom: 12px;
		.player-preview__embed-code {
			word-break: break-all;
			background: #fff;
			border-radius: 4px;
			border: 1px solid #f4f4f4;
			height: 150px;
			font-size: 12px;
			flex: 1;
			padding: 12px;
			margin-right: 5px;
			color: #575353;
		}
		.player-preview__btn {
			margin-top: 1px;
		}
		&.player-preview__embed-code-preview-link input {
			height: 36px;
		}
	}
	.player-preview__btn {
		border-radius: 4px;
		border: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 25px;
		width: 25px;
		background: #e5eaed;
		img {
			width: 20px;
			height: 20px;
		}
		&:hover img,
		&:focus img {
			height: 25px;
			width: 25px;
		}
	}
	&__embed-section {
		label {
			display: block;
			font-weight: 600;
			margin: 12px 0 5px 0;
		}
	}
	&__video-actions {
		display: flex;
		.player-preview__btn {
			margin-right: 12px;
		}
	}
}

.player-config-select-option {
	min-height: auto !important;
	&__primary-text {
		font-weight: 600;
		margin-bottom: 5px;
	}
	&__default-label {
		height: 45px;
	}
}
.player-config-select__subheader {
	font-size: 10px;
	color: #a2a2a2;
	text-transform: uppercase;
	padding: 10px 16px;
}
#player-config-select .player-config-select-option {
	&__default-label {
		height: auto;
	}
	&__secondary-text {
		display: none;
	}
	&__primary-text {
		font-weight: normal;
		margin-bottom: 0px;
	}
}
