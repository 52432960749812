.medias-display-wrapper {
	&:not(.medias-display-wrapper--mini) {
		background-color: #f7f8fa;
	}
	&--no-media {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;

		.noMediaMessage {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transform: translateY(-50%);

			img {
				margin-left: auto;
				margin-right: auto;
				width: 81px;
				height: 89px;
			}

			label {
				color: #818181;
				font-size: 11px;
				margin: 15px;
				font-weight: 400;
			}
		}
	}

	.scenario-empty-view {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0 5%;
		margin-top: -10%;

		.video-wrapper {
			width: 80%;
			max-width: 430px;
		}

		.content-wrapper {
			margin-left: 50px;
			width: 100%;
			max-width: 400px;

			& h2 {
				font-family: 'Lora', serif !important;
				font-size: 45px;
				font-weight: bold;
				margin-bottom: 10px;

				@media only screen and (max-width: 2100px) {
					& {
						font-size: 45px;
					}
				}

				@media only screen and (max-width: 1200px) {
					& {
						font-size: 37px;
					}
				}
				@media only screen and (max-width: 1024px) {
					& {
						font-size: 30px;
					}
				}
				@media only screen and (max-width: 768px) {
					& {
						font-size: 30px;
					}
				}
			}

			& .subtitle {
				margin-bottom: 30px;
			}

			& .button {
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
}

.medias-display-skeleton {
	&__grid {
		overflow: auto;
		min-height: 21vh;
		max-height: 100%;
		padding: 24px;
		display: grid;
		grid-gap: 1.5rem;
		grid-auto-rows: 1fr;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		background-color: #f7f8fa;
	}

	&__list {
		width: 100%;
		padding: 24px;
		span[aria-live='polite'] {
			height: 65px;
			display: block;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		span[aria-live='polite'] {
			&:first-child {
				height: 130px;
			}
			height: 30px;
		}
	}
	.react-loading-skeleton {
		display: block;
		background-color: rgba(0, 0, 0, 0.04);
		border-radius: 4px;
	}
}

body[screen-type='tablet'],
body[screen-type='small'],
body[screen-type='mobile'] {
	.scenario-empty-view {
		flex-direction: column;
		.video-wrapper {
			padding-bottom: 24px;
		}
		.content-wrapper {
			margin-left: 0;
			text-align: center;
		}
	}
}
